<template>
  <a-table size="small" :columns="tableColumns" :dataSource="dataSource" :pagination="false">
    <div slot="enable" slot-scope="value, item">
      <a-switch :defaultChecked="item.enable" size="small" @change="(value) => changeEnable(value, item)" />
    </div>
    <div slot="sequential" slot-scope="value, item">
      <a-input-number
        :defaultValue="item.sequential"
        :min="0"
        size="small"
        @change="(value) => changeSequential(value, item)"
      />
    </div>
  </a-table>
</template>

<script>
export default {
  components: {},
  props: ["value"],
  data() {
    return {
      moduleItems: [
        { module: "实时库存统计", enable: false, sequential: 1 },
        { module: "库位状态", enable: false, sequential: 2 },
        { module: "货品库存前十占比", enable: false, sequential: 3 },
        { module: "今日入库单统计", enable: false, sequential: 4 },
        { module: "今日上架单统计", enable: false, sequential: 5 },
        { module: "今日拣货单统计", enable: false, sequential: 6 },
        { module: "入库趋势图", enable: false, sequential: 7 },
        { module: "出库趋势图", enable: false, sequential: 8 },
        { module: "库存趋势图", enable: false, sequential: 9 },
        { module: "实时出入库量信息概览", enable: false, sequential: 10 },
      ],

      tableColumns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          width: 80,
          customRender: (_, __, index) => index + 1,
        },
        {
          title: this.$t("模块"),
          dataIndex: "module",
        },
        {
          title: this.$t("启用状态"),
          dataIndex: "enable",
          scopedSlots: { customRender: "enable" },
        },
        {
          title: this.$t("排序"),
          dataIndex: "sequential",
          scopedSlots: { customRender: "sequential" },
        },
      ],
    };
  },
  computed: {
    dataSource() {
      const moduleItems = [...this.moduleItems];
      for (const item of this.value) {
        const index = moduleItems.findIndex((moduleItem) => moduleItem.module === item.module);
        if (index !== -1) {
          moduleItems[index]["enable"] = item["enable"] ?? moduleItems[index]["enable"];
          moduleItems[index]["sequential"] = item["sequential"] ?? moduleItems[index]["sequential"];
        }
      }
      return moduleItems;
    },
  },
  methods: {
    changeEnable(value, item) {
      const moduleItems = [...this.dataSource];
      const index = moduleItems.findIndex((moduleItem) => moduleItem.module === item.module);
      if (index !== -1) {
        moduleItems[index]["enable"] = value;
        this.$emit("change", moduleItems);
      }
    },
    changeSequential(value, item) {
      console.log(value, item);
      const moduleItems = [...this.dataSource];
      const index = moduleItems.findIndex((moduleItem) => moduleItem.module === item.module);
      if (index !== -1) {
        moduleItems[index]["sequential"] = value;
        this.$emit("change", moduleItems);
      }
    },
  },
};
</script>
